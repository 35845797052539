var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "timeline-item" }, [
    _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "item-border" }, [
        _c(
          "span",
          {
            staticClass: "dot",
            class: _vm.getBackgroundColour(_vm.itemTimeline.status),
          },
          [
            _vm.itemTimeline.status === "CREDIT"
              ? _c("i", { staticClass: "fa fa-plus icon-status" })
              : _c("i", { staticClass: "fa fa-minus icon-status" }),
          ]
        ),
        _c(
          "h4",
          {
            staticClass: "title-item",
            class: _vm.getTextColor(_vm.itemTimeline.status),
          },
          [_vm._v(" " + _vm._s(_vm.itemTimeline.title) + " ")]
        ),
        _c("p", { staticClass: "description-item" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm._f("moment")(_vm.itemTimeline.description, "DD/MM/YYYY")
              ) +
              " "
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }