<template>
  <section class="timeline-item">
    <div class="item">
      <div class="item-border">
        <span :class="getBackgroundColour(itemTimeline.status)" class="dot">
          <i 
            v-if="itemTimeline.status === 'CREDIT'"
            class="fa fa-plus icon-status"
          />
          <i
            v-else 
            class="fa fa-minus icon-status"
          />
        </span>
        <h4 class="title-item" :class="getTextColor(itemTimeline.status)">
          {{ itemTimeline.title }}
        </h4>
        <p class="description-item">
          {{ itemTimeline.description | moment('DD/MM/YYYY') }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TimelineItem',
  props: {
    itemTimeline: {
      type: Object,
      default: () => ({})
    },
    colorDots: {
      type: String,
      default: '#2da1bf'
    },
    dateLocale: {
      type: String,
      default: ''
    }
  },
  methods: {
    getBackgroundColour(status) {
      return status === 'DEBIT' ? `bg-danger` : `bg-success`
    },
    getTextColor(status) {
      return status === 'DEBIT' ? 'text-danger' : 'text-success'
    },
    // getFormattedDate(item) {
    //   const locale = this.dateLocale || navigator.language
    //   const nameMonth = item.from.toLocaleDateString(locale, { month: 'long' })
    //   if (item.showDayAndMonth) {
    //     const day = item.from.getDate()
    //     return `${day}. ${nameMonth}`
    //   }
    //   return nameMonth
    // }
  }
}
</script>

<style lang="scss" scoped>
.timeline-item {
  .item {
    border-left: 5px solid #ccd5db;
    padding: 10px 0 10px 15px;
    position: relative;
  }
  .item-border {
    border: 1px solid #ccd5db;
    padding: 5px;
    border-radius: 5px;
    // position: relative;
  }
  .date-item {
    margin: 0;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 2px;
    border-left: 5px solid #ccd5db;
  }
  .title-item {
    margin: 0;
    padding: 5px 0;
    font-size: 15px;
    font-weight: 500;
  }
  .description-item {
    font-weight: 100;
    margin: 0;
  }
  .dot {
    display: block;
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    left: -14px;
    top: 26px;
  }
  .icon-status {
    margin-left: 5px;
    margin-top: 4px;
  }
}
</style>