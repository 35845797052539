var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "timeline" }, [
    _vm.hasItems
      ? _c(
          "div",
          { staticClass: "wrapper-timeline" },
          _vm._l(_vm.timelineItems, function (timelineContent, timelineIndex) {
            return _c(
              "div",
              { key: timelineIndex, staticClass: "unique-timeline" },
              [
                _c("TimelineItem", {
                  attrs: {
                    "item-timeline": timelineContent,
                    "date-locale": _vm.dateLocale,
                    "color-dots": _vm.colorDots,
                  },
                }),
              ],
              1
            )
          }),
          0
        )
      : _c("p", [_vm._v(_vm._s(_vm.messageWhenNoItems))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }